// Be sure you take the time to create a nice 404 page.
import * as React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";

const wrapper = {
  padding: "3rem",
  marginTop: "3rem",
};

const NotFoundPage = () => {
  return (
    <>
      <Helmet
        title="404 - Not found!"
        titleTemplate={`%s - Amigo Mexican Restaurant`}
        meta={[
          {
            name: `description`,
            content: "The page that you are looking for could not be found.",
          },
        ]}
      ></Helmet>

      <main className="py-5">
        <section className="section-centered-title">
          <div className="centered-title container d-flex">
            <div className="text-center mx-auto">
              <div className="intro-title">
                <h1 style={{ color: "yellow" }}>404</h1>
              </div>
            </div>
          </div>
        </section>
        <div className="container py-5" style={wrapper}>
          <h1>404 Not Found</h1>
          <p>The page that you are looking for could not be found.</p>
          <Link className="btn btn-primary  btn-hover-icon" to="/">
            Go home
          </Link>
        </div>
      </main>
    </>
  );
};

export default NotFoundPage;
